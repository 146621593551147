/* eslint-disable complexity */
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API_CONSTANT from '../../src/constants/ApiConstant';
import {
  AppConstant,
  ASYNC_KEY,
  FEED_SELECT_TYPE
} from '../../src/constants/AppConstant';
import {
  getFeedAPI,
  getFeedUnAuthorizedAPI
} from '../../src/store/home/Action';
import { setPostData } from '../../src/store/home/Reducer';
import { SubHeader, Posts } from '../../src/components/postComp';
import {
  getFromAsync,
  ROUTE,
  setInAsyncStorage,
  showError
} from '../../src/utils';
import { CircularProgress } from '@mui/material';
import * as track from '../../src/utils/MixpanelTrackEvent';
import { AppContext } from '../../src/context/AppProvider';
import { NextSeo } from 'next-seo';
import { useInView } from 'react-intersection-observer';
import { useRouter } from 'next/router';

let page = -1;
let apiCall = false;

function Home({ feedData }) {
  const dispatch = useDispatch();
  const {
    postFeed,
    postFeed: { post: items },
    global,
    login
  } = useSelector(state => state);

  const router = useRouter();

  const isPaginationStop = useRef(false);

  const [feedLoader, setFeedLoader] = useState(false);
  const [_preventEvent, setPreventEvent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [feeds, setFeeds] = useState(false);
  const [lottie, setLottie] = useState(false);
  const [checkLocal, setCheckLocal] = useState(null);
  const { isAuthenticated } = login;
  const context = useContext(AppContext);

  useEffect(() => {
    const feed = getFromAsync(ASYNC_KEY.FEED_TYPE);
    const token = localStorage.getItem(ASYNC_KEY.ACCESS_TOKEN);

    if ((!feed || feed === 'popular') && !token) {
      dispatch(setPostData(feedData));
      page = 0;
    }
  }, [feedData, dispatch]);

  useEffect(() => {
    let tokenData = getFromAsync(ASYNC_KEY?.ACCESS_TOKEN);
    setCheckLocal(tokenData);

    track.trackFeedOpen(
      AppConstant?.FEED,
      AppConstant?.DISABLED,
      AppConstant?.WHATSAPP_B,
      isAuthenticated
    );

    const feed = getFromAsync(ASYNC_KEY.FEED_TYPE);
    setFeeds(feed);
    const token = localStorage.getItem(ASYNC_KEY.ACCESS_TOKEN);

    if (!postFeed?.post?.length && token) {
      page = 0;
      setIsLoading(true);
      feedApiFun({
        pageCount: 0,
        offset: 0,
        feed: feed !== null ? feed : FEED_SELECT_TYPE.POPULAR,
        items: items,
        isLoad: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const feedApiFun = async ({ pageCount, offset, feed, items, isLoad }) => {
    if (!apiCall) {
      setLottie(true);
      setInAsyncStorage(
        ASYNC_KEY?.FEED_TYPE,
        feed !== null ? feed : FEED_SELECT_TYPE.POPULAR
      );
      page = pageCount;
      apiCall = true;
      setFeedLoader(isLoad);
      const limit = API_CONSTANT.LIMIT;
      const offsets = offset;
      const type = feed !== null ? feed : FEED_SELECT_TYPE.POPULAR;
      const count = 1;

      const tokenData = await getFromAsync(ASYNC_KEY.ACCESS_TOKEN);
      const queryParams = tokenData
        ? `?limit=${limit}&offset=${offsets}&type=${type}&daily_session_number=${count}`
        : `?limit=${limit}&offset=${offsets}&type=${type}`;
      const API = tokenData ? getFeedAPI : getFeedUnAuthorizedAPI;
      if (isAuthenticated && pageCount > 0) {
        track.trackScroll10Posts(AppConstant.TRACK_HOME, AppConstant.FEED);
      }
      return await API(dispatch, queryParams)
        .then(res => {
          setLottie(false);
          apiCall = false;
          setIsLoading(false);
          setFeedLoader(false);
          setInAsyncStorage(ASYNC_KEY?.FEED_TYPE, type);
          if (!res?.data?.data?.results?.length) {
            isPaginationStop.current = true;
          }
          dispatch(setPostData([...items, ...res?.data?.data?.results]));
        })
        .catch(err => {
          setLottie(false);
          setIsLoading(false);
          setFeedLoader(false);
          showError(err);
        })
        .finally(() => {
          setLottie(false);
          setIsLoading(false);
          setFeedLoader(false);
        });
    }
  };

  const { ref, inView, entry } = useInView({
    threshold: 0
  });

  const postDetail = async () => {
    setLottie(true);
    const feed = getFromAsync(ASYNC_KEY.FEED_TYPE);
    const tokenData = await getFromAsync(ASYNC_KEY.ACCESS_TOKEN);
    feedApiFun({
      pageCount: page + 1,
      offset: (page + 1) * 10,
      feed: feed,
      items: items,
      isLoad: false
    });
  };

  useEffect(() => {
    if (inView) {
      if (!isPaginationStop.current) {
        postDetail();
      }
    }
  }, [inView]);

  return (
    <div className="w-full">
      <NextSeo canonical={process.env.NEXT_PUBLIC_URL + '/home'} />
      <div style={{ width: 'inherit' }}>
        <div className="text-center">
          <div>
            <SubHeader
              feedType={val => {
                setFeeds(val);
                feedApiFun({
                  pageCount: 0,
                  offset: 0,
                  feed: val,
                  items: [],
                  isLoad: true
                });
              }}
            />
            {feedLoader ? (
              <div className="text-center">
                <CircularProgress sx={{ mt: 2 }} />
              </div>
            ) : isLoading ? (
              <div className="w-full justify-center">
                <CircularProgress sx={{ mt: 2 }} />
              </div>
            ) : (
              <Fragment>
                <Posts
                  truncIt
                  lottie={lottie}
                  data={postFeed?.post?.length ? postFeed?.post : feedData}
                  getFeedListUpdate={val => {
                    feedApiFun({
                      pageCount: 0,
                      offset: 0,
                      feed: feeds,
                      items: [],
                      isLoad: val !== undefined ? false : true
                    });
                  }}
                />
                <div
                  ref={ref}
                  style={{
                    height: 10,
                    width: '100%',
                    background: 'transparent'
                  }}
                ></div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export async function getStaticProps(context) {
  let data = {};
  let communityData = {};
  let trendingData = {};
  try {
    const res = await fetch(
      `${
        API_CONSTANT.BASE_URL + API_CONSTANT.FETCH_FEED_UNAUTHORIZED
      }?limit=50&offset=0&type=top_of_the_week`,
      {
        method: 'GET',
        headers: {
          'Accept-Encoding': 'br'
        }
      }
    );

    data = await res.json();

    const communityRes = await fetch(
      `${API_CONSTANT.BASE_URL}/unauthorized/autocomplete/community/`,
      {
        method: 'GET',
        headers: {
          'Accept-Encoding': 'br'
        }
      }
    );

    const trendingRes = await fetch(
      `${API_CONSTANT.BASE_URL}/unauthorized/trending/`,
      {
        method: 'GET',
        headers: {
          'Accept-Encoding': 'br'
        }
      }
    );

    trendingData = await trendingRes.json();

    communityData = await communityRes.json();
  } catch (error) {}

  return {
    props: {
      feedData: data?.data?.results || [],
      communityData: communityData?.data?.results || [],
      trendingData: trendingData?.data || []
    },
    revalidate: 21600 // 6 hours
  };
}

export default Home;
